@import '../variables';

.loader-wrapper {
	align-items: center;
	display: flex;
	justify-content: center;
	min-height: 25rem;
	padding: 2rem;
	z-index: 9999;

	.loading-icon {
		display: flex;
		height: 10rem;
		justify-content: center;
		position: relative;
		width: 10rem;

		div {
			animation: loading 0.75s cubic-bezier(0, 0.5, 0.5, 1) infinite;
			background: $light-blue;
			display: inline-block;
			position: absolute;
			width: 1.2rem;

			&:nth-child(1) {
				animation-delay: -0.25s;
				left: 1.8rem;
			}

			&:nth-child(2) {
				animation-delay: -0.125s;
				left: 4.4rem;
			}

			&:nth-child(3) {
				animation-delay: 0s;
				left: 7rem;
			}
		}
	}

	@keyframes loading {
		0% {
			height: 8rem;
			top: 1rem;
		}
		50%, 100% {
			height: 4rem;
			top: 3rem;
		}
	}
}

.card {
	background-color: white;
	box-shadow: 0 0 0.4rem 0 $light-gray;
	display: flex;
	flex-direction: column;
	margin: 1rem;
	padding: 1rem;
	transition: 0.25s;

	.card-body {
		height: 100%;
	}

	.card-title {
		font-size: 2.4rem;
		font-weight: 500;
	}

	.card-text {
		color: $dark-gray;
		font-size: 1.8rem;
		font-weight: 100;
		line-height: 2.6rem !important;
	}

	.card-img {
		flex-shrink: 0;
		width: 100%;
	}

	.card-footer {
		margin-top: 1rem;
	}

	.card-button-wrapper {
		display: flex;
		width: 100%;

		.button {
			align-items: center;
			display: flex;
			flex: 1;
			font-size: 1.6rem;
			font-weight: 300;
			justify-content: center;
			margin: 0;

			.button-icon {
				display: flex;
				margin-left: -0.6rem;
				margin-right: 0.6rem;
			}

			&.btn-secondary {
				background-color: $light-blue;
				text-shadow: 0.2rem 0.2rem 0.2rem rgba($dark-blue, 0.25);

				&:hover {
					background-color: darken($light-blue, 25%);
				}
			}

			&.btn-info {
				background-color: $med-gray;
				text-shadow: 0.2rem 0.2rem 0.2rem rgba($dark-gray, 0.25);

				&:hover {
					background-color: darken($med-gray, 25%);
				}
			}

			&:first-child {
				border-bottom-left-radius: 0.5rem;
				border-top-left-radius: 0.5rem;
			}

			&:last-child {
				border-bottom-right-radius: 0.5rem;
				border-top-right-radius: 0.5rem;
			}
		}
	}

	@media screen and (max-width: $break-med) {
		.card-img {
			width: 25rem;
		}
	}

	@media screen and (max-width: $break-small) {
		.card-text {
			font-size: 1.4rem !important;
			line-height: 2rem !important;
		}
	}

	@media screen and (max-width: $break-large) {
		.card-title {
			font-size: 2rem;
		}

		.card-button-wrapper {
			.button {
				font-size: 1.4rem;
				padding: 0.5rem;
			}
		}
	}
}

.post-list-item {
	background-color: $light-gray;
	box-shadow: 0.2rem 0.2rem 0.75rem 0 rgba($med-gray, 0.5);
	font-size: 2rem;
	margin: 2rem 0;
	transition: 0.25s;

	.post-list-item-link {
		color: $dark-gray;
		display: flex;
		flex-direction: row;
		padding: 4rem 1rem;
		transition: 0.25s;

		.left-column {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			flex: 1;
			max-width: 20rem;
		}

		.right-column {
			display: flex;
			flex: 3;
			flex-direction: column;
			justify-content: center;
		}

		.post-list-item-timestamp {
			color: $med-gray;
			font-size: 2rem;
			position: absolute;
			right: 1.4rem;
			top: 1rem;
		}

		img {
			height: 10rem;
		}

		p {
			color: $dark-gray;
			margin-bottom: 0;
			line-height: 2rem;
			transition: 0.25s;
		}
	}

	&:hover {
		background-color: $light-blue;
		cursor: pointer;

		.post-list-item-link {
			color: $white;

			p {
				color: white;
			}
		}
	}

	&:last-child {
		margin-bottom: 0;
	}

	@media screen and (max-width: $break-small) {
		.post-list-item-link {
			img {
				height: 7rem;
			}

			.post-list-item-title {
				font-size: 2.4rem;
			}
		}
	}

	@media screen and (max-width: $break-tiny) {
		.post-list-item-link {
			align-items: center;
			flex-direction: column;
			padding: 1rem;
			text-align: center;

			.left-column {
				align-items: center;
				flex-direction: column;
				justify-content: center;
			}

			.right-column {
				align-items: center;
				flex-direction: column;
				justify-content: center;
			}

			.post-list-item-timestamp {
				color: $med-gray;
				font-size: 1.6rem;
				position: relative;
			}

			.post-list-item-title {
				font-size: 2.8rem;
			}

			img {
				margin: 2rem;
			}

			p {
				margin-bottom: 1rem;
			}
		}
	}
}

.button-group {
	display: flex;
	justify-content: center;
}

.button {
	background-color: $med-gray;
	color: white;
	font-size: 1rem;
	font-weight: 300;
	margin: 1rem;
	padding: 1rem;
	transition: 0.125s;

	&:hover {
		background-color: $dark-gray;
	}
}

.post-tag-wrapper {
	align-items: center;
	box-shadow: 0.2rem 0.2rem 0.25rem 0 $blue;
	border-radius: 2rem;
	display: flex;
	justify-content: center;
	margin: 0 0.5rem;

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: 0;
	}

	&:hover {
		box-shadow: 0.2rem 0.2rem 0.25rem 0 $pink;
		cursor: pointer;
	}

	&:active {
		a {
			background-color: $light-blue;
		}
	}

	a {
		background-color: $med-gray;
		border: none;
		border-radius: 2rem;
		color: $white;
		font-size: 1.4rem !important;
		line-height: 2.4rem !important;
		padding: 0 1rem;
		transition: 0.25s;

		@media screen and (max-width: $break-small) {
			font-size: 1.2rem !important;
		}
	}
}

.tags-list-wrapper {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	row-gap: 1rem;

	&.inline {
		display: inline-flex;
	}
}
