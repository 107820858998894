@font-face {
  font-family: Quicksand;
  src: url("Quicksand-Variable.67fb546e.ttf") format("truetype-variations");
  font-weight: 1 999;
}

*, * * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
}

html {
  width: 100%;
  height: 100%;
  font-size: 62.5%;
}

body {
  background-color: #6483e8;
  width: 100%;
  height: 100%;
  font-family: Quicksand, sans-serif;
  overflow: hidden;
}

body #root {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

body p {
  color: #6c757d;
  margin-bottom: 2.4rem;
  font-size: 2rem;
  font-weight: 300;
  line-height: 3.6rem;
}

body a {
  font-size: 2rem;
  text-decoration: none;
}

body h1, body h2, body h3, body h4, body h5, body h6 {
  margin-bottom: 1rem;
  font-weight: 400;
}

body h1 {
  font-size: 4.8rem;
}

body h2 {
  font-size: 4rem;
}

body h3 {
  font-size: 3.6rem;
}

body h4 {
  font-size: 2.8rem;
}

body h5 {
  font-size: 2.4rem;
}

body h6 {
  font-size: 2rem;
}

body pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

body pre code {
  border-radius: 1rem;
  font-size: 1.8rem;
}

body hr {
  border-top: .1rem solid #0000001a;
  margin: 1rem;
}

.loader-wrapper {
  z-index: 9999;
  justify-content: center;
  align-items: center;
  min-height: 25rem;
  padding: 2rem;
  display: flex;
}

.loader-wrapper .loading-icon {
  justify-content: center;
  width: 10rem;
  height: 10rem;
  display: flex;
  position: relative;
}

.loader-wrapper .loading-icon div {
  background: #599bee;
  width: 1.2rem;
  animation: .75s cubic-bezier(0, .5, .5, 1) infinite loading;
  display: inline-block;
  position: absolute;
}

.loader-wrapper .loading-icon div:first-child {
  animation-delay: -.25s;
  left: 1.8rem;
}

.loader-wrapper .loading-icon div:nth-child(2) {
  animation-delay: -.125s;
  left: 4.4rem;
}

.loader-wrapper .loading-icon div:nth-child(3) {
  animation-delay: 0s;
  left: 7rem;
}

@keyframes loading {
  0% {
    height: 8rem;
    top: 1rem;
  }

  50%, 100% {
    height: 4rem;
    top: 3rem;
  }
}

.card {
  background-color: #fff;
  flex-direction: column;
  margin: 1rem;
  padding: 1rem;
  transition: all .25s;
  display: flex;
  box-shadow: 0 0 .4rem #f0f0f0;
}

.card .card-body {
  height: 100%;
}

.card .card-title {
  font-size: 2.4rem;
  font-weight: 500;
}

.card .card-text {
  color: #373737;
  font-size: 1.8rem;
  font-weight: 100;
  line-height: 2.6rem !important;
}

.card .card-img {
  flex-shrink: 0;
  width: 100%;
}

.card .card-footer {
  margin-top: 1rem;
}

.card .card-button-wrapper {
  width: 100%;
  display: flex;
}

.card .card-button-wrapper .button {
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 1.6rem;
  font-weight: 300;
  display: flex;
}

.card .card-button-wrapper .button .button-icon {
  margin-left: -.6rem;
  margin-right: .6rem;
  display: flex;
}

.card .card-button-wrapper .button.btn-secondary {
  text-shadow: .2rem .2rem .2rem #09109940;
  background-color: #599bee;
}

.card .card-button-wrapper .button.btn-secondary:hover {
  background-color: #135ab5;
}

.card .card-button-wrapper .button.btn-info {
  text-shadow: .2rem .2rem .2rem #37373740;
  background-color: #6c757d;
}

.card .card-button-wrapper .button.btn-info:hover {
  background-color: #313539;
}

.card .card-button-wrapper .button:first-child {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.card .card-button-wrapper .button:last-child {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

@media screen and (width <= 992px) {
  .card .card-img {
    width: 25rem;
  }
}

@media screen and (width <= 768px) {
  .card .card-text {
    font-size: 1.4rem !important;
    line-height: 2rem !important;
  }
}

@media screen and (width <= 1200px) {
  .card .card-title {
    font-size: 2rem;
  }

  .card .card-button-wrapper .button {
    padding: .5rem;
    font-size: 1.4rem;
  }
}

.post-list-item {
  background-color: #f0f0f0;
  margin: 2rem 0;
  font-size: 2rem;
  transition: all .25s;
  box-shadow: .2rem .2rem .75rem #6c757d80;
}

.post-list-item .post-list-item-link {
  color: #373737;
  flex-direction: row;
  padding: 4rem 1rem;
  transition: all .25s;
  display: flex;
}

.post-list-item .post-list-item-link .left-column {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  max-width: 20rem;
  display: flex;
}

.post-list-item .post-list-item-link .right-column {
  flex-direction: column;
  flex: 3;
  justify-content: center;
  display: flex;
}

.post-list-item .post-list-item-link .post-list-item-timestamp {
  color: #6c757d;
  font-size: 2rem;
  position: absolute;
  top: 1rem;
  right: 1.4rem;
}

.post-list-item .post-list-item-link img {
  height: 10rem;
}

.post-list-item .post-list-item-link p {
  color: #373737;
  margin-bottom: 0;
  line-height: 2rem;
  transition: all .25s;
}

.post-list-item:hover {
  cursor: pointer;
  background-color: #599bee;
}

.post-list-item:hover .post-list-item-link, .post-list-item:hover .post-list-item-link p {
  color: #fff;
}

.post-list-item:last-child {
  margin-bottom: 0;
}

@media screen and (width <= 768px) {
  .post-list-item .post-list-item-link img {
    height: 7rem;
  }

  .post-list-item .post-list-item-link .post-list-item-title {
    font-size: 2.4rem;
  }
}

@media screen and (width <= 576px) {
  .post-list-item .post-list-item-link {
    text-align: center;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }

  .post-list-item .post-list-item-link .left-column, .post-list-item .post-list-item-link .right-column {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .post-list-item .post-list-item-link .post-list-item-timestamp {
    color: #6c757d;
    font-size: 1.6rem;
    position: relative;
  }

  .post-list-item .post-list-item-link .post-list-item-title {
    font-size: 2.8rem;
  }

  .post-list-item .post-list-item-link img {
    margin: 2rem;
  }

  .post-list-item .post-list-item-link p {
    margin-bottom: 1rem;
  }
}

.button-group {
  justify-content: center;
  display: flex;
}

.button {
  color: #fff;
  background-color: #6c757d;
  margin: 1rem;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 300;
  transition: all .125s;
}

.button:hover {
  background-color: #373737;
}

.post-tag-wrapper {
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  margin: 0 .5rem;
  display: flex;
  box-shadow: .2rem .2rem .25rem #1254a8;
}

.post-tag-wrapper:first-child {
  margin-left: 0;
}

.post-tag-wrapper:last-child {
  margin-right: 0;
}

.post-tag-wrapper:hover {
  cursor: pointer;
  box-shadow: .2rem .2rem .25rem #bb2d74;
}

.post-tag-wrapper:active a {
  background-color: #599bee;
}

.post-tag-wrapper a {
  color: #fff;
  background-color: #6c757d;
  border: none;
  border-radius: 2rem;
  padding: 0 1rem;
  transition: all .25s;
  font-size: 1.4rem !important;
  line-height: 2.4rem !important;
}

@media screen and (width <= 768px) {
  .post-tag-wrapper a {
    font-size: 1.2rem !important;
  }
}

.tags-list-wrapper {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  display: flex;
}

.tags-list-wrapper.inline {
  display: inline-flex;
}

.lJfTYG_scrollRevealer {
  align-items: inherit;
  display: inherit;
  flex-direction: inherit;
  justify-content: inherit;
}

.lJfTYG_scrollRevealer.lJfTYG_isRevealed {
  opacity: 1;
  transition: all 1s ease-in-out;
  bottom: 0;
}

.lJfTYG_scrollRevealer.lJfTYG_isHidden {
  opacity: 0;
  bottom: -10rem;
}

.content {
  background-color: #fefefe;
  min-height: 100%;
}

.content .container {
  max-width: 110rem;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (width <= 1200px) {
  .content .container {
    max-width: 90rem;
  }
}

@media screen and (width <= 992px) {
  .content .container {
    max-width: 70rem;
  }
}

@media screen and (width <= 768px) {
  .content .container {
    max-width: 50rem;
  }
}

.content .header {
  text-align: center;
  background: linear-gradient(0deg, #500e8a 33%, #1254a8 100%) fixed;
  flex-direction: column;
  margin-bottom: 0;
  padding: 8rem 10rem 12rem;
  display: flex;
}

.content .header .header-title {
  color: #fff;
  text-shadow: .3rem .3rem .2rem #091099;
  font-size: 8rem;
}

@media screen and (width <= 768px) {
  .content .header .header-title {
    font-size: 5rem;
  }
}

.content .header .header-sub-title {
  color: #fff;
  text-align: center;
  text-shadow: .3rem .3rem .2rem #091099;
  font-size: 2.4rem;
  font-weight: 200;
}

@media screen and (width <= 768px) {
  .content .header .header-sub-title {
    font-size: 2rem;
  }
}

@media screen and (width <= 1200px) {
  .content .header {
    padding: 7rem;
  }
}

.content .main-nav {
  z-index: 999;
  background-color: #373737;
  position: sticky;
  top: 0;
  box-shadow: 0 .25rem .5rem .125rem #222;
}

.content .main-nav ul {
  justify-content: space-evenly;
  align-items: center;
  list-style: none;
  display: flex;
}

.content .main-nav .nav-item {
  flex: 1;
  justify-content: center;
  display: flex;
}

.content .main-nav .nav-item.disabled .nav-link {
  color: #6c757d;
  white-space: nowrap;
}

.content .main-nav .nav-item.disabled .nav-link:hover {
  color: #6c757d;
}

.content .main-nav .nav-item.disabled .coming-soon {
  color: #fff;
  background-color: #bb2d74;
  border-radius: 10rem;
  align-items: center;
  margin: 0 .6rem;
  padding: .6rem;
  font-size: .9rem;
  line-height: 1;
  display: inline-flex;
  top: -.5rem;
}

@media screen and (width <= 768px) {
  .content .main-nav .nav-item.disabled .coming-soon {
    padding: .4rem;
    font-size: .75rem;
    top: -.4rem;
  }
}

.content .main-nav .nav-item .nav-link {
  color: #599bee;
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;
  border-bottom: .5rem solid #0000;
  height: 100%;
  padding: 2rem 1.5rem 1.5rem;
  font-size: 2.4rem;
  font-weight: 200;
  text-decoration: none;
  transition: all .125s;
}

.content .main-nav .nav-item .nav-link:hover, .content .main-nav .nav-item .nav-link:active, .content .main-nav .nav-item .nav-link.active {
  color: #fff;
  transition: all .125s;
}

.content .main-nav .nav-item .nav-link.active {
  text-shadow: .3rem .3rem .2rem #222;
  border-bottom-color: #599bee;
  font-weight: 700;
}

.content .main-nav .nav-item .nav-link:before {
  content: attr(title);
  visibility: hidden;
  height: 0;
  font-weight: 700;
  display: block;
  overflow: hidden;
}

@media screen and (width <= 1200px) {
  .content .main-nav .nav-item .nav-link {
    font-weight: 300;
  }
}

@media screen and (width <= 576px) {
  .content .main-nav .nav-item .nav-link {
    font-size: 1.8rem;
  }
}

.content .main-content-wrapper {
  margin-top: 3rem;
  margin-bottom: 1rem;
  padding: 1rem;
}

.content .main-content-wrapper:before {
  content: " ";
  opacity: 1;
  z-index: 999;
  pointer-events: none;
  background-color: #fff;
  width: 100%;
  height: 100%;
  transition: opacity 1s;
  position: absolute;
  left: 0;
  right: 0;
}

.content .main-content-wrapper.has-loaded:before {
  opacity: 0;
}

.content .main-content-wrapper p, .content .main-content-wrapper a {
  line-height: 3rem;
}

.content .main-content-wrapper small p, .content .main-content-wrapper small a {
  font-size: 1.6rem;
}

.content .main-content-wrapper section {
  margin-bottom: 5rem;
}

.content .main-content-wrapper section:last-child {
  margin-bottom: 1rem;
}

.content .main-content-wrapper section .section-header {
  border-bottom: .1rem solid #0000001a;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
}

.content .main-content-wrapper section .section-header .section-title {
  text-shadow: .3rem .3rem .2rem #1254a820;
  margin-bottom: 0;
  font-size: 4.8rem;
}

@media screen and (width <= 992px) {
  .content .main-content-wrapper section .section-header {
    margin-bottom: 1rem;
  }

  .content .main-content-wrapper section .section-header .section-title {
    font-size: 3.6rem;
  }

  .content .main-content-wrapper {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .content .main-content-wrapper p, .content .main-content-wrapper a {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .content .main-content-wrapper small p, .content .main-content-wrapper small a {
    font-size: 1.4rem;
  }
}

.content .footer {
  border-top: .1rem solid #0000001a;
  justify-content: space-between;
  padding: 1rem 0;
  display: flex;
}

.content .footer .footer-icon {
  display: flex;
}

.content .footer .footer-icon.copyright {
  margin-right: .5rem;
}

.content .footer p {
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.content .footer a {
  color: #599bee;
  -webkit-user-select: none;
  user-select: none;
  font-size: 2rem;
  font-weight: 300;
  line-height: 3.2rem;
  transition: all .125s;
}

.content .footer a:hover {
  color: #135ab5;
  transition: color .125s;
}

.content .footer .social-links {
  justify-content: center;
  align-items: center;
  display: flex;
}

.content .footer .social-links li {
  align-items: center;
  padding: 0 .5rem;
  line-height: 3.6rem;
  display: flex;
}

.content .footer .social-links li.middot {
  color: #373737;
  font-size: 2rem;
}

@media screen and (width <= 576px) {
  .content .footer {
    margin: 0 1rem;
  }
}

@media screen and (width <= 992px) {
  .content .footer p, .content .footer a {
    font-size: 1.6rem;
  }
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.link-highlight {
  color: #599bee;
  transition: color .125s;
}

.link-highlight:hover {
  color: #135ab5;
  transition: color .125s;
}

.rainbow-hover {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(to left, #599bee) !important;
}

.rainbow-hover:hover {
  text-decoration: none;
  color: #0000 !important;
  background-image: linear-gradient(to left, violet, indigo, #00f, green, #ff0, orange, red) !important;
}

/*# sourceMappingURL=index.ae40459d.css.map */
