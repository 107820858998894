// Misc SCSS variables

$darker-gray: #222222;
$dark-gray: #373737;
$med-gray: #6c757d;
$gray: #999999;
$light-gray: #f0f0f0;
$white: #ffffff;
$surf: #47b49c;
$blue: #1254a8;
$light-blue: #599bee;
$dark-blue: #091099;
$purple: #500e8a;
$pink: #bb2d74;

$break-tiny: "576px";
$break-small: "768px";
$break-med: "992px";
$break-large: "1200px";
